import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { PrismicNextImage, PrismicNextLink } from '@prismicio/next';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { DesktopBreakpoint, ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import { convertToSnakeCase } from '../BlogContent';
import styles from './VerticalTabs.module.scss';
/**
 * Props for `VerticalTabs`.
 */
export type VerticalTabsProps = SliceComponentProps<Content.VerticalTabsSlice>;

/**
 * Component for "VerticalTabs" Slices.
 */
const VerticalTabs = ({ slice }: VerticalTabsProps): JSX.Element => {
  const { items, primary } = slice;
  const { heading, sub_heading, tag_text, background_color } = primary;
  const [activeTab, setActiveTab] = useState(0);
  const [width] = useWindowsSize();
  const [bg, setBg] = useState();
  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    }
  }, [])
  const getContentToRender = index => {
    const socialProof: any = items[typeof index !== 'undefined' ? index : activeTab].social_proof;
    switch (socialProof.type) {
      case 'block_quote': {
        const { content, title, sub_title } = socialProof.data;
        return (
          <div className={styles.blockQuote}>
            <PrismicRichText field={content} />
            <footer>
              <div className={styles.title}>{title}</div>
              <div className={styles.subTitle}>{sub_title}</div>
            </footer>
          </div>
        );
      }
      case 'logos': {
        const { logos } = socialProof.data;
        return (
          <div className={styles.logos}>
            {logos.map(item => (
              <PrismicNextImage key={item.logo.url} field={item.logo} />
            ))}
          </div>
        );
      }
      case 'case_study_v2': {
        const { title, image, uid } = socialProof.data;
        return (
          <div className={styles.caseStudy}>
            <PrismicNextImage field={image} width={88} height={88} />
            <div className={styles.textWrapper}>
              <div className={styles.pill}>CASE STUDY</div>
              <p className={styles.title}>{title}</p>
            </div>
            <PrismicNextLink href={`/case-study/${uid}`}>Read</PrismicNextLink>
          </div>
        );
      }
      default:
        return <></>;
    }
  };
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={classNames(styles.root, 'wide-container', styles[bg || background_color], styles[slice.variation], 'color-changing-section')}
      data-background={background_color}
    >
      <div className={classNames(styles.content)}>
        <div className={styles.header}>
          <h2>{heading}</h2>
          <h4>{sub_heading}</h4>
        </div>
        <div className={styles.tabsContainer}>
          <div className={styles.tabs}>
            <div className={styles.tagText}>{tag_text}</div>
            <div className={styles.verticalLine}></div>
            {items.map((tab, index) => (
              <div
                key={index}
                className={`${styles.tab} ${activeTab === index ? styles.active : ''}`}
                onClick={e => {
                  if (width < DesktopBreakpoint) {
                    document.getElementById(convertToSnakeCase(tab.heading)).scrollIntoView({
                      behavior: 'smooth'
                    });
                  }
                  setActiveTab(index);
                }}
              >
                <div className={styles.tabTitle}>{tab.heading}</div>
                {activeTab === index && (
                  <>
                    <div className={styles.description}>{tab.description}</div>
                    <PrismicNextLink className={styles.link} field={tab.cta_url}>
                      {tab.cta_text}
                    </PrismicNextLink>
                  </>
                )}
                <div
                  className={classNames(styles.tabContent, styles.hideDesktop, {
                    [styles.hideIFNotActive]: index !== activeTab,
                  })}
                  id={convertToSnakeCase(tab.heading)}
                >
                  <PrismicNextImage field={items[activeTab].image} />
                  <div className={styles.tabBody}>{getContentToRender(undefined)}</div>
                </div>
              </div>
            ))}
          </div>
          {items.map((item, index) => (
            <div
              className={classNames(styles.tabContent, styles.hideTabletAndBelow, {
                [styles.hideIFNotActive]: index !== activeTab,
              })}
              key={item.heading}
            >
              <PrismicNextImage field={items[activeTab].image} />
              <div className={styles.tabBody}>{getContentToRender(index)}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default VerticalTabs;
